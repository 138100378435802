/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader }">
    <CDetailViewer
      v-if="activeDemandeur && activeDemandeur.id"
      title="Fichier/CV du DE"
      ref="detailViewer"
      :item="activeDemandeur"
    >
      <FicheAzoli :readOnly="true" :demandeurId="activeDemandeur.id" />
      <DemandeurMonCv :readOnly="true" :demandeurId="activeDemandeur.id" />
    </CDetailViewer>
    <div class="bg- mt-2 mb-2">
      <div class="col-sm-12 col-md-12">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="border- shadow-0 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row">
              <div class="col">
                <div class="input-group">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      :multiple="false"
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurType"
                      :options="demandeurTypeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Type de DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurVulnerabilite"
                      :options="demandeurVulnerabiliteOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Vulnérabilité'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurSexe"
                      :options="demandeurSexeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Sexe DE'"
                      display="chip"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i>Matching Local</a
                >
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i>Matching Global</a
                >
              </div>
            </div>
          </form>
          <transition>
            <div class="border-0 shadow-0 mt-2">
              <div class="row p-fluid grid">
                <template v-for="(option, index) in filterOptions">
                  <div
                    class="col-12 col-sm-6 col-md-3 mb-4"
                    v-if="option.visible"
                    :key="index"
                  >
                    <!-- <label for="basic">{{option.label}}</label> -->
                    <!-- <c-auto-complete v-if="option.url" display="chip" v-model="option.value" chips :option="option" :force-selection="true" /> -->
                    <!-- <p-multi-select v-model="option.value" :options="getItemsOf(option.getterName)" :optionLabel="option.labelField" :selectionLimit="3" :placeholder="option.label" optionValue="id" :filter="true" display="chip"/> -->
                    <AsyncMultiSelect
                      v-model="option.value"
                      :multiple="true"
                      :queryUrl="option.queryUrl"
                      :optionLabel="option.labelField"
                      :placeholder="option.label"
                      :emitObject="false"
                      v-if="option.queryUrl"
                      :displayMode="option.displayMode"
                      :searchOptions="[option.labelField]"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="option.value"
                      :options="option.items"
                      v-else
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="option.label"
                      display="chip"
                    />
                  </div>
                </template>
              </div>
              <div class="d-flex justify-content-end">
                <PButton
                  class="p-button-danger p-button- py-1 p-button-sm ms-1"
                  @click.prevent.stop="
                    filterOptions.forEach((option) => (option.value = []))
                  "
                >
                  Réinitialiser les filtres
                </PButton>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="container- mt-1">
      <p-divider />
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4 class="d-flex">Liste des profils ({{ mxTotalCount }} éléments trouvés)</h4>
        </div>

        <div class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>
      <div class="d-flex align-items-center flex-wrap text-wrap mb-4">
        <h6 class="text-uppercase" v-if="mxActiveFiltersCount > 0">
          Filtres appliquées:
        </h6>
        <template v-for="(option, index) in filterOptions">
          <!-- <div v-if="option.value != null && option.value.length != 0" :key="index" class="mb-2 border border-primary pt-2"  style="width: 320px"> -->
          <template v-if="option.multiple">
            <PTag
              severity="warning"
              class="ms-2 mb-2"
              v-for="(item, i) in option.value"
              :key="index + '_' + i"
            >
              <small class="p-1 text-caption">
                {{ item[option.labelField] }}
                <PButton
                  v-tooltip.top="`Retirer du filtre`"
                  icon="pi pi-times cursor-pointer"
                  class="p-button-danger p-button-sm ms-1 p-0"
                  @click.prevent.stop="
                    option.value = option.value.filter((el) => el != item)
                  "
                  style="height: 14px; width: 14px"
                />
              </small>
            </PTag>
          </template>

          <template v-else>
            <PTag
              severity="warning"
              class="ms-2 mb-2"
              :key="index + '_' + i"
              v-if="option.value"
            >
              <small class="p-1 text-caption">
                {{ option.value }}
                <PButton
                  v-tooltip.top="`Retirer du filtre`"
                  icon="pi pi-times cursor-pointer"
                  class="p-button-danger p-button-sm ms-1 p-0"
                  @click.prevent.stop="option.value = null"
                  style="height: 14px; width: 14px"
                />
              </small>
            </PTag>
          </template>
        </template>
        <!-- <PButton v-if="mxActiveFiltersCount > 0" class="p-button-danger p-button-outlined py-1 p-button-sm ms-1" @click.prevent.stop="filterOptions.forEach((option) => option.value = [])">
            Réinitialiser les filtres
          </PButton> -->
      </div>
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="row mb-4" v-if="viewMode == 'card'">
            <div
              class="col-sm-4 col-lg-3 mb-3"
              v-for="de in mxFilteredItems"
              :key="de.id"
            >
              <div class="style_profil_card p-3 pt-3">
                <div
                  @click.stop="showDemandeur(de)"
                  class="d-flex justify-content-center mb-3"
                >
                  <azoli-status-badge :de="de" :key="'_statut_c_' + de.id" />
                </div>
                <div class="mx-auto">
                  <a href="#">
                    <div
                      @click="uploadFile(de)"
                      class="rounded-circle style_bg_img border"
                      :style="`background-image: url(${
                        de.fullPhotoUrl != null
                          ? de.fullPhotoUrl
                          : '/assets/img/profilMan.jpg'
                      }); height: 100px; width: 100px;`"
                    ></div>
                  </a>
                </div>
                <div class="py-3 text-center border-bottom">
                  <div
                    class="d-flex no-wrap justify-content-center align-items-center text-muted"
                  >
                    <!-- <small class="mt-1">4,5 | 7 avis</small> -->
                  </div>
                  <h6 class="my-1">
                    <a href="#" class="text-dark text-uppercase"
                      >{{ de.nom }} {{ de.prenom }}</a
                    >
                  </h6>

                  <small v-if="de.validePar" class="text-muted text-bold"
                    >Validé par: {{ de.validePar }}
                    <span class="text-uppercase"
                      >, le {{ de.validationDate | formatDate }}</span
                    ></small
                  >
                  <small v-else-if="de.creePar" class="text-muted"
                    >Réalisé par: {{ de.creePar.nom }}
                    {{ de.validePar }}
                    <span class="text-uppercase"
                      >, le {{ de.lastLogin | formatDate }}</span
                    ></small
                  >
                  <small v-else class="text-muted"
                    >Réalisé par:
                    <span class="text-bold">LUI-MEME</span>
                    <span class="text-uppercase"
                      >, le {{ de.lastLogin | formatDate }}</span
                    ></small
                  >
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <a
                    class="btn btn-info rounded-0 btn-sm me-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Consulter et modifier"
                    @click.stop="showDemandeur(de)"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <a
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    title="Générer PDF"
                    @click.stop.prevent="downloadFiche(de)"
                    ><i class="bi bi-printer"></i
                  ></a>
                  <a
                    @click.stop.prevent="remplirFiche(de)"
                    title="Saisir la fiche"
                    class="btn btn-warning rounded-0 btn-sm me-1"
                    ><i class="bi bi-file-earmark-check"></i
                  ></a>
                  <button
                    title="Valider la fiche me-1"
                    @click.prevent="validerFiche(de)"
                    class="btn btn-light btn-sm"
                  >
                    <i
                      :class="{
                        'text-danger': de.status == 'VALIDE',
                      }"
                      class="bi bi-clipboard-check"
                    ></i>
                  </button>
                  <button
                    title="Prise en charge me-1"
                    @click.prevent="priseEnCharge(de)"
                    class="btn btn-primary btn-sm"
                  >
                    <i class="bi bi-file-earmark-medical"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="d-flex flex-wrap justify-content-center align-items-center mt-3"
              >
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxTotalCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div v-else class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(statut)="{ item: de }">
                  <div class="d-flex justify- mb-3">
                    <azoli-status-badge :de="de" />
                  </div>
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span class="text-uppercase">{{ de.prenom }}</span>
                </template>
                <!-- <template #cell(creePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.creePar"
                    >{{ de.creePar.nom }} {{ de.creePar.prenom }}</span
                  >
                </template> -->
                <template #cell(estSuivi)="{ item }">
                  <div class="position-relative">
                    <PTag
                      v-tooltip.left="
                        item.estSuiviPar != null
                          ? 'Suivi par ' + item.estSuiviPar
                          : 'Pas encore suivi'
                      "
                      rounded
                      :severity="
                        item.estSuivi
                          ? item.estAutoAffecte
                            ? 'info'
                            : 'primary'
                          : 'danger'
                      "
                      size="large"
                      >{{
                        item.estSuivi
                          ? item.estAutoAffecte
                            ? "Auto.Affecté"
                            : "Affecté"
                          : "Non.Affecté"
                      }}</PTag
                    >
                  </div>
                </template>
                <template #cell(validePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.validePar"
                    >{{ de.validePar.nom }} {{ de.validePar.prenom }}</span
                  >
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <PTabPanel header="Prise En Charge">
                        <PriseEnCharge
                          :readOnly="true"
                          v-if="activeTab == 0"
                          :demandeurId="row.item.id"
                        />
                      </PTabPanel>
                      <PTabPanel header="CV">
                        <template v-if="activeTab == 1">
                          <FicheAzoli
                            :readOnly="true"
                            v-if="row.item.estPPI"
                            :demandeurId="row.item.id"
                          />
                          <DemandeurMonCv
                            :readOnly="true"
                            v-else
                            :demandeurId="row.item.id"
                          />
                        </template>
                      </PTabPanel>
                      <!-- <PTabPanel header="Tâches plannifiées">
                        <ActiviteAnpeList
                          :extraQueryArg="`participantId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Liste programmes">
                        <ParticipationProgrammeList
                          :extraQueryArg="`participantId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Consignes">
                        <ConsigneList :extraQueryArg="`participantId=${row.item.id}`" />
                      </PTabPanel>
                      <PTabPanel header="Prescriptions">
                        <PrescriptionList
                          :extraQueryArg="`participantId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Placements"> Content III </PTabPanel>
                      <PTabPanel header="Candiatures"> </PTabPanel>
                      <PTabPanel header="Payements"> </PTabPanel>
                      <PTabPanel header="Statistiques"> Content III </PTabPanel> -->
                    </PTabView>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <!-- <a class="btn btn-success rounded-0 btn-sm me-1" title
                      ><i class="bi bi-pencil-square"></i
                    ></a> -->
                    <!-- <a class="btn btn-info rounded-0 btn-sm me-1" title="Consulter et modifier"   @click="mxToggleDetails(row, tableData)" ><i class="bi bi-info-square"></i></a> -->
                    <!-- <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a> -->
                    <!-- <a
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      title="Générer PDF"
                      @click.stop.prevent="downloadFiche(row.item)"
                      ><i class="bi bi-printer"></i
                    ></a>
                    <a
                      @click.stop.prevent="remplirFiche(row.item)"
                      title="Saisir la fiche"
                      class="btn btn-warning rounded-0 btn-sm me-1"
                      ><i class="bi bi-file-earmark-check"></i
                    ></a>
                    <a
                      @click.stop.prevent="transfertSingleDemandeur(row.item)"
                      title="Affecter"
                      class="btn btn-warning rounded-0 btn-sm me-1"
                      ><i class="bi bi-box-arrow-up-right"></i
                    ></a>
                    <button
                      title="Valider la fiche"
                      @click.prevent="validerFiche(row.item)"
                      class="btn btn-light btn-sm"
                    >
                      <i
                        :class="{
                          'text-danger': row.item.status == 'VALIDE',
                        }"
                        class="bi bi-clipboard-check"
                      ></i>
                    </button> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }}
                à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div
            class="col-sm-4 col-lg-3 mb-3"
            v-for="i in mxPagination.rowsPerPage"
            :key="i"
          >
            <div class="style_profil_card p-3 pt-3">
              <div class="custom-skeleton p-3">
                <div
                  class="d-flex flex-column mb-2 align-items-center justify-content-center"
                >
                  <PSkeleton width="10rem" class="mb-2"></PSkeleton>
                  <PSkeleton shape="circle" size="6rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import DeStatistic from "../../../components/espace/demandeur/statistic/DeStatistic.vue";
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CDetailViewer from "../../../components/common/CDetailViewer.vue";

import ConseillerSelector from "../../../components/espace/antenne/transfert/ConseillerSelector.vue";
import AntenneSelectorDialog from "../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue";
// import * as XLSX from 'xlsx'
import ActiviteAnpeList from "./ActiviteAnpeList.vue";
import ConsigneList from "./ConsigneList.vue";
import PrescriptionList from "./PrescriptionList.vue";
import ParticipationProgrammeList from "./ParticipationProgrammeList.vue";
import PriseEnCharge from "./PriseEnCharge.vue";
import DemandeurMonCv from "../demandeur/DemandeurMonCv.vue";
import FicheAzoli from "../conseiller/DemandeurDetailAzoli.vue";

import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";

export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideStatistic: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DeStatistic,
    FicheAzoliReport,
    FicheAzoli,
    ReportHeader,
    ConseillerSelector,
    DemandeurMonCv,
    AntenneSelectorDialog,
    ActiviteAnpeList,
    ConsigneList,
    CDetailViewer,
    PrescriptionList,
    ParticipationProgrammeList,
    AzoliStatusBadge,
    PriseEnCharge,
    CAutoComplete,
    AsyncMultiSelect,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      demandeurSexe: null,
      demandeurType: null,
      demandeurVulnerabilite: null,
      parAeEnregistres: [],
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des demandeurs d'emploi",
                subtitle: "Liste des demandeurs d'emploi",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "demandeurs/rechercherProfils/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des demandeurs trouvés.xlsx",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                apiPath: "demandeurs/rechercherProfils/exportToExcel",
                fetcherMethod: "demandeurs/rechercherProfils/exportToExcel",
                queryParams: params,
                fileName: "Liste des demandeurs trouvés.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      // searchFields: [ 'nom' ],
      // searchFieldsOptions: [
      //   { label: 'Bénéficiaire' , value: 'nom', visible: this.$can('filter.beneficiaire', 'Azoli') },
      //   { label: 'Whatsapp' , value: 'whatsapp', visible: this.$can('filter.whatsapp', 'Azoli') },
      //   { label: 'Commune' , value: 'commune', visible: this.$can('filter.commune', 'Azoli') },
      //   { label: 'Département' , value: 'departement', visible: this.$can('filter.departement', 'Azoli') },
      //   { label: 'AE (Nom & prénom)' , value: 'ae', visible: this.$can('filter.ae', 'Azoli') },
      //   { label: 'Arrondissement' , value: 'arrondissement', visible: this.$can('filter.arrondissement', 'Azoli') },
      //   { label: 'Village/Quartier de ville' , value: 'ville', visible: this.$can('filter.ville', 'Azoli') },
      //   { label: 'Métier Appris' , value: 'metierAppris',  visible: this.$can('filter.metierAppris', 'Azoli') },
      //   { label: 'Métier Désiré' , value: 'metierDesire',  visible: this.$can('filter.metierDesire', 'Azoli') }
      // ],

      searchFields: ["nomPrenom"],
      searchFieldsOptions: [
        {
          label: "Référence offre",
          value: "offre",
          visible: true,
        },
        {
          label: "Nom(s) et Prénom(s)",
          value: "nomPrenom",
          visible: true,
        },
        // {
        //   label: "Nature du contrat",
        //   value: "nature",
        //   visible: true,
        // },
      ],
      filterOptions: [
        {
          column: "typeCheminEmploi",
          value: "",
          key: "label",
          label: "Type D'emploi Positionnement",
          valueKey: "value",
          dropdown: true,
          multiple: false,
          visible: true,
          items: [
            { value: null, label: "Tous" },
            { value: "SALARIE", label: "SALARIE" },
            { value: "INDEPENDANT", label: "INDEPENDANT" },
          ],
        },
        {
          column: "annExp",
          value: "",
          label: "Expériences",
          labelField: "label",
          valueKey: "label",
          displayMode: "classic",
          queryUrl: "anneeExpriences",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "langue",
          value: [],
          label: "Langues",
          dropdown: true,
          labelField: "nom",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "ethnies",
          visible: true,
          multiple: true,
        },
        {
          column: "metierAppris",
          value: "",
          label: "Métier(s) appris",
          valueKey: "id",
          queryUrl: "romes",
          displayMode: "classic",
          labelField: "libelle",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "metierDesires",
          value: "",
          label: "Métier(s) recherché(s)",
          queryUrl: "romes",
          valueKey: "id",
          labelField: "libelle",
          displayMode: "classic",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "competence",
          value: "",
          label: "Aptitudes",
          getterName: "competences",
          valueKey: "id",
          displayMode: "classic",
          labelField: "intitule",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        // {
        //   column: "diplome",
        //   value: "",
        //   label: "Diplômes",
        //   valueKey: "id",
        //   dropdown: true,
        //   displayMode: "classic",
        //   labelField: "code",
        //   queryUrl: "niveaux",
        //   visible: true,
        // },
        {
          column: "niveauInstructionIds",
          value: "",
          label: "Niveau instruction",
          dropdown: true,
          valueKey: "id",
          labelField: "code",
          displayMode: "classic",
          queryUrl: "instructions",
          visible: true,
          multiple: true,
        },
        {
          column: "programmesPrescrits",
          value: "",
          labelField: "nom",
          label: "Programme prescrit",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "programmes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        // {
        //     column: "nature",
        //     value: "",
        //     label: "Nature contrat",
        //     labelField: "code",
        //     displayMode: "classic",
        //     queryUrl: "natures",
        //     valueKey: "id",
        //     dropdown: true,
        //     visible: true,
        // },
        {
          column: "departementIds",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "classic",
          valueKey: "id",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "communeIds",
          value: "",
          labelField: "nom",
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "arrondissementIds",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "villeIds",
          value: "",
          labelField: "nom",
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    extraQueryArg() {
      //   this.queryStatistics();
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      pagination: "demandeur/pagination",
      demandeurs: "demandeur/demandeurs",
      domaines: "domaine/domaines",
      departements: "departement/departements",
      natures: "nature/natures",
      niveaux: "niveau/niveaux",
      instructions: "instruction/instructions",
      pieces: "piece/typePieces",
      anneeExpriences: "setting/anneeExpriences",
      romes: "rome/romes",
      ethnies: "ethnie/ethnies",
      demandeur: "demandeur/demandeur",
      entreprises: "entreprise/entreprises",
      demandeurTypeOptions: "setting/demandeurTypeOptions",
      demandeurSexeOptions: "setting/demandeurSexeOptions",
      demandeurVulnerabiliteOptions: "setting/demandeurVulnerabiliteOptions",
    }),
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
        { key: "departement.nom", label: "Département" },
        { key: "statut", label: "Statut" },
        { key: "estSuivi", label: "Traité par" },
        { key: "validePar", label: "Validé par" },
        "actions",
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchRechercherProfils: "demandeur/fetchRechercherProfils",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",

      fetchDemandeurs: "demandeur/fetchDemandeurs",
      fetchCompetences: "competence/fetchCompetences",
      fetchDomaines: "domaine/fetchDomaines",
      fetchDepartements: "departement/fetchDepartements",
      fetchCommunes: "departement/fetchCommunes",
      fetchArrondisements: "departement/fetchArrondisements",
      fetchVilles: "departement/fetchVilles",
      fetchNiveaux: "niveau/fetchNiveaux",
      fetchInstructions: "instruction/fetchInstructions",
      fetctNatures: "nature/fetchNatures",
      fetchTypePieces: "piece/fetchTypePieces",
      fetchRomes: "rome/fetchRomes",
      fetchEthnies: "ethnie/fetchEthnies",
      selectdemandeur: "demandeur/getOneDemandeur",
      fetchEntreprises2: "entreprise/fetchEntreprises2",
    }),
    getItemsOf(getterName) {
      return this.$store.getters[getterName];
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "demandeur",
        search: this.search,
        fetcherMethod: "demandeur/fetchRechercherProfils",
        dataGetter: "demandeur/demandeurs",
        paginationGetter: "demandeur/pagination",
        autoSubmitQuery: false,
        submitQueryOnInit: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("regroupements", this.demandeurType);
          paramPlus = this.mxAddQueryParam(
            "vulnerabilite",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("sexe", this.demandeurSexe, paramPlus);

          let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    downloadFiche(de) {
      // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
      // this.downloadAzoli(de).then(()=>{})
      this.fetchDossier({
        demandeurId: de.id,
      }).then(() => {
        console.log(this.$refs.html2Pdf);
        this.$refs.html2Pdf.generatePdf();
      });
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
    showDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$refs.detailViewer.show();
      // this.$router.push({ name: 'espace.ce.de.detail', params: { demandeurId: demandeur.id }})
    },
    remplirFiche(demandeur) {
      if (!demandeur.estPPI) {
        this.$router.push({
          name: "espace.de.moncv",
          params: { demandeurId: demandeur.id },
        });
      } else {
        this.$router.push({
          name: "espace.ce.azoli.edit",
          params: { demandeurId: demandeur.id },
        });
      }
    },
    async validerFiche(demandeur) {
      if (demandeur.status == "VALIDE") {
        this.$toast.info("Fiche déjà validée!", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      if (demandeur.status != "TERMINE") {
        this.$toast.error("Fiche non terminée", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      await this.$confirm.require({
        group: "confirmDialog",
        message: `Vous-vous vraiment valider  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}?`,
        header: "Confirmation",
        rejectClass: "d-none",
        accept: () => {
          this.validerDossier({
            ...demandeur,
            status: "VALIDE",
          }).then((data) => {
            console.log(data);
            this.$toast.success("Fiche validée avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    async destroyDemandeur(demandeur) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: "Voulez-vous vraiment supprimer cet élément?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteDemandeur(demandeur);
        },
      });
    },
    updateOrCreateDemandeur(demandeur) {
      console.log(demandeur);
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
